<template>
  <v-col class="mt-1">
    <v-col class="pa-6">
      <v-col class="px-0">
        <v-col class="shades transparent">
          <v-row>
            <v-col cols="12" md="12">
              <h2 class="font-weight-bold headline hidden-sm-and-down">
                홍보를 원하는 채널의<br />채널별 혜택을 설정해주세요.
              </h2>
            </v-col>
            <v-col class="mt-2" cols="12" v-if="form.naver">
              <div class="line mb-6"></div>
              <!--              <v-checkbox v-if="adsData.type === 'A'" v-model="naver_limit" label="제한 없음" color="rouge" class="check ma-0" height="20px"></v-checkbox>-->
              <div class="body-1 font-weight-bold my-2">
                모집 희망인원<span class="caption ml-1 rouge--text">*필수</span>
              </div>
              <!--              <v-checkbox v-if="_.includes(form.naver.type, 'S')" v-model="naver_limit" label="제한 없음" color="rouge" class="check ma-0" height="20px" hide-details></v-checkbox>-->
              <v-row v-if="!naver_limit">
                <v-col class="pa-1" cols="8" v-if="$moment.tz('Asia/Seoul').isAfter(form.naver.startDate)">
                  <v-slider
                    v-if="_.includes(form.naver.type, 'N')"
                    v-model.number="naver_number"
                    @change="checkDone"
                    always-dirty
                    :min="10"
                    :max="100"
                    :tick-labels="ticknLabels"
                    :tick-size="0"
                    :step="10"
                    show-ticks="always"></v-slider>
                  <v-slider
                    v-else-if="_.includes(form.naver.type, 'S')"
                    v-model.number="naver_number"
                    @change="checkDone"
                    always-dirty
                    :min="10"
                    :max="100"
                    :tick-labels="ticksLabels"
                    :tick-size="0"
                    :step="10"
                    show-ticks="always"></v-slider>
                </v-col>
                <v-col class="pa-1" cols="8" v-else>
                  <v-slider
                    v-if="_.includes(form.naver.type, 'N')"
                    v-model.number="naver_number"
                    @change="checkDone"
                    always-dirty
                    :min="10"
                    :max="100"
                    :tick-labels="ticknLabels"
                    :tick-size="0"
                    :step="10"
                    show-ticks="always"></v-slider>
                  <v-slider
                    v-else-if="_.includes(form.naver.type, 'S')"
                    v-model.number="naver_number"
                    @change="checkDone"
                    always-dirty
                    :min="10"
                    :max="100"
                    :tick-labels="ticksLabels"
                    :tick-size="0"
                    :step="10"
                    show-ticks="always"></v-slider>
                </v-col>
                <v-col cols="4" md="3" class="pl-6">
                  <v-text-field
                    v-model.number="naver_number"
                    class="mt-0"
                    type="number"
                    :suffix="adsData.type === 'A' ? '명' : '명/월'"
                    readonly>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <div class="body-1 font-weight-bold my-4 mt-6">
                  등급별 제공 혜택<span class="caption ml-1 rouge--text">*필수</span>
                </div>
                <v-row>
                  <v-col cols="12">
                    <v-row style="align-items: flex-end">
                      <v-col cols="3" md="1" style="align-self: center">
                        <blackIcon class="level_icon" />
                      </v-col>
                      <v-col class="ml-1" cols="8" md="6">
                        <div class="mb-2">
                          {{ adsData.deliveryMethod !== 'PR' ? '블랙 제공 혜택' : '블랙 등급 제공 품목' }}
                        </div>
                        <v-text-field
                          v-model="form.naver.black"
                          name="naverBlack"
                          type="text"
                          class="text-right"
                          :rules="[errors.first('naverBlack') || !errors.has('naverBlack')]"
                          :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="3" v-if="$vuetify.breakpoint.smAndDown"> </v-col>
                      <v-col class="ml-1" cols="4" md="2">
                        <div class="mb-2">
                          <p v-if="adsData.deliveryMethod === 'PR'" style="font-size: 12px; color: #bd1328">
                            *택배비 포함
                          </p>
                          <p>일반 소비자가</p>
                        </div>
                        <v-text-field
                          v-model.number="form.naver.blackFullPrice"
                          name="naverBlackFullPrice"
                          type="number"
                          suffix="원"
                          class="text-right"
                          :rules="[errors.first('naverBlackFullPrice') || !errors.has('naverBlackFullPrice')]"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col class="ml-1" cols="4" md="2">
                        <div class="mb-2">
                          {{ adsData.deliveryMethod !== 'PR' ? '슈퍼멤버스 특별가' : '구매 후 환급금' }}
                        </div>
                        <v-text-field
                          v-model.number="form.naver.blackPayment"
                          name="naverBlackPayment"
                          type="number"
                          suffix="원"
                          class="text-right"
                          :rules="[errors.first('naverBlackPayment') || !errors.has('naverBlackPayment')]"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row style="align-items: flex-end">
                      <v-col cols="3" md="1" style="align-self: center">
                        <redIcon class="level_icon" />
                      </v-col>
                      <v-col class="ml-1" cols="8" md="6">
                        <div class="mb-2">
                          {{ adsData.deliveryMethod !== 'PR' ? '레드 제공 혜택' : '레드 등급 제공 품목' }}
                        </div>
                        <v-text-field
                          v-model="form.naver.red"
                          name="naverRed"
                          type="text"
                          class="text-right"
                          :rules="[errors.first('naverRed') || !errors.has('naverRed')]"
                          :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col cols="3" v-if="$vuetify.breakpoint.smAndDown"> </v-col>
                      <v-col class="ml-1" cols="4" md="2">
                        <div class="mb-2">
                          <p v-if="adsData.deliveryMethod === 'PR'" style="font-size: 12px; color: #bd1328">
                            *택배비 포함
                          </p>
                          <p>일반 소비자가</p>
                        </div>
                        <v-text-field
                          v-model.number="form.naver.redFullPrice"
                          name="naverRedFullPrice"
                          type="number"
                          suffix="원"
                          class="text-right"
                          :rules="[errors.first('naverRedFullPrice') || !errors.has('naverRedFullPrice')]"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                      <v-col class="ml-1" cols="4" md="2">
                        <div class="mb-2">
                          {{ adsData.deliveryMethod !== 'PR' ? '슈퍼멤버스 특별가' : '구매 후 환급금' }}
                        </div>
                        <v-text-field
                          v-model.number="form.naver.redPayment"
                          name="naverRedPayment"
                          type="number"
                          suffix="원"
                          class="text-right"
                          :rules="[errors.first('naverRedPayment') || !errors.has('naverRedPayment')]"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row style="align-items: flex-end">
                      <v-col cols="3" md="1" style="align-self: center">
                        <yellowIcon class="level_icon" />
                      </v-col>
                      <v-col class="ml-1" cols="8" md="6">
                        <div class="mb-2">
                          {{ adsData.deliveryMethod !== 'PR' ? '옐로우 제공 혜택' : '옐로우 등급 제공 품목' }}
                        </div>
                        <v-text-field
                          v-model="form.naver.yellow"
                          name="naverYellow"
                          type="text"
                          class="text-right"
                          :rules="[errors.first('naverYellow') || !errors.has('naverYellow')]"
                          :placeholder="adsData.deliveryMethod === 'A' ? '제공 제품명' : '제공 제품명'"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat
                          hide-details></v-text-field>
                      </v-col>
                      <v-col cols="3" v-if="$vuetify.breakpoint.smAndDown"> </v-col>
                      <v-col class="ml-1" cols="4" md="2">
                        <div class="mb-2">
                          <p v-if="adsData.deliveryMethod === 'PR'" style="font-size: 12px; color: #bd1328">
                            *택배비 포함
                          </p>
                          <p>일반 소비자가</p>
                        </div>
                        <v-text-field
                          v-model.number="form.naver.yellowFullPrice"
                          name="naverYellowFullPrice"
                          type="number"
                          suffix="원"
                          class="text-right"
                          :rules="[errors.first('naverYellowFullPrice') || !errors.has('naverYellowFullPrice')]"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat
                          hide-details></v-text-field>
                      </v-col>
                      <v-col class="ml-1" cols="4" md="2">
                        <div class="mb-2">
                          {{ adsData.deliveryMethod !== 'PR' ? '슈퍼멤버스 특별가' : '구매 후 환급금' }}
                        </div>
                        <v-text-field
                          v-model.number="form.naver.yellowPayment"
                          name="naverYellowPayment"
                          type="number"
                          suffix="원"
                          class="text-right"
                          :rules="[errors.first('naverYellowPayment') || !errors.has('naverYellowPayment')]"
                          @input="checkDone"
                          :disabled="form.naver.available === 0"
                          single-line
                          outlined
                          solo
                          flat
                          hide-details></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-6">
                      <v-col v-if="adsData.deliveryMethod === 'PR'" class="caption primary--text ml-8" cols="12"
                        >*광고등록이 완료된 후 결제관리 메뉴에서 "구매 후 환급금"을 충전해주세요. "구매 후 환급금"은
                        5%의 수수료가 부과됩니다.</v-col
                      >
                    </v-row>
                  </v-col>
                  <div v-if="form.naver.available === 0" class="body-1 mb-2 secondary--text">
                    광고가 게시중인 상태이므로 직접 수정이 불가합니다.<br />변경을 원하시는 경우 우측 하단 채팅버튼을
                    통해 수정을 요청해주세요.
                  </div>
                </v-row>
              </v-row>
              <div class="body-1 font-weight-bold mb-2 mt-4">
                필수 키워드<span class="caption ml-1 rouge--text">*필수</span>
              </div>
              <div class="label mb-2">네이버 블로그에서 어떤 키워드로 노출되기를 원하시나요?</div>
              <v-row>
                <v-chip
                  v-for="(keyword, index) in form.requiredKeywords"
                  :key="index"
                  style="height: 40px"
                  class="mt-2"
                  @click:close="removeRequiredKeyword(index)"
                  close
                  color="rouge"
                  label
                  outlined
                  :disabled="form.naver.available === 0"
                  >{{ keyword }}</v-chip
                >
                <v-text-field
                  :disabled="form.naver.available === 0"
                  v-model="requiredKeyword"
                  name="requiredKeyword"
                  type="text"
                  placeholder="예) 다이어트 샐러드"
                  class="mt-2"
                  @keyup.enter="addRequiredKeyword"
                  single-line
                  outlined
                  solo
                  flat
                  hide-details></v-text-field>
                <v-btn :disabled="form.naver.available === 0" color="rouge" @click="addRequiredKeyword" class="plus_btn"
                  ><v-icon dark>add</v-icon></v-btn
                >
                <v-col class="my-0" cols="12" md="12">
                  <span class="caption rouge--text">*필수 키워드는 최대 3개까지 설정할 수 있습니다.</span>
                </v-col>
              </v-row>
              <div class="body-1 font-weight-bold mb-2 mt-4">추천 키워드</div>
              <div class="label mb-2">네이버 블로그 후기에 포함되었으면 하는 선택키워드가 있으신가요?</div>
              <v-row>
                <v-chip
                  v-for="(keyword, index) in this.form.keywords"
                  :key="index"
                  style="height: 40px"
                  class="mt-2"
                  @click:close="removeKeyword(index)"
                  close
                  color="rouge"
                  label
                  outlined
                  >{{ keyword }}</v-chip
                >
                <v-text-field
                  v-model="keyword"
                  name="keyword"
                  type="text"
                  :rules="[errors.first('keyword') || !errors.has('keyword')]"
                  placeholder="예) 다이어트 샐러드"
                  class="mt-2"
                  @keyup.enter="addKeyword"
                  :disabled="form.naver.available === 0"
                  single-line
                  outlined
                  solo
                  flat
                  hide-details></v-text-field>
                <v-btn color="rouge" @click="addKeyword" class="plus_btn" :disabled="form.naver.available === 0"
                  ><v-icon dark>add</v-icon></v-btn
                >
              </v-row>
              <v-col cols="12" md="12" class="pt-2">
                <div class="label my-2">원고료</div>
                <v-col cols="4">
                  <v-text-field
                    type="number"
                    class="mt-2 text-right"
                    v-model.number="form.diamondPoint"
                    @input="checkDone"
                    suffix="원"
                    single-line
                    outlined
                    solo
                    flat
                    hide-details
                    disabled></v-text-field>
                </v-col>
                <v-col class="my-0" cols="12" md="12">
                  <span class="caption rouge--text">*인당 원고료는 0원 ~ 최대 500,000원까지 설정 가능합니다.</span>
                </v-col>
              </v-col>
            </v-col>
            <v-col class="mt-2" cols="12" md="9" v-else>
              <h3 class="font-weight-bold my-6">네이버 블로그 채널관리</h3>
              <v-btn color="rouge" @click="showAdsStart('naver', 'start')" flat outlined style="width: 140px"
                >채널 추가하기</v-btn
              >
            </v-col>
            <div class="line mt-6"></div>
            <v-col cols="12" md="12">
              <h3 class="font-weight-bold mt-6 mb-2">혜택 관련 정보</h3>
            </v-col>
            <v-col class="mt-2" cols="12" md="10">
              <div class="body-1 font-weight-bold mb-2">이용제한 여부</div>
              <div class="body-1 mb-2">슈퍼멤버스 회원에게 제품을 제공할 때, 품목 제한이 필요하신가요?</div>
              <v-btn-toggle v-model="isLimitation" @change="checkDone" mandatory>
                <v-btn
                  :value="false"
                  active-class="rouge--text text--rouge v-btn--active"
                  class="ma-0"
                  style="width: 130px"
                  outlined
                  flat
                  >아니오</v-btn
                >
                <v-btn
                  :value="true"
                  active-class="rouge--text text--rouge v-btn--active"
                  class="ma-0"
                  style="width: 130px"
                  outlined
                  flat
                  >예</v-btn
                >
              </v-btn-toggle>
            </v-col>
            <v-col v-if="isLimitation" cols="12" md="10">
              <div class="body-1 font-weight-bold mt-6 mb-2">이용제한 내용</div>
              <v-text-field
                v-if="isLimitation"
                v-model="channel.limitation"
                name="limitation"
                type="text"
                :rules="[errors.first('limitation') || !errors.has('limitation')]"
                hint="예) A, B, C 제품 중 C 제품은 할인이 적용되지 않습니다."
                persistent-hint
                @input="checkDone"
                single-line
                outlined
                solo
                flat></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="adsStart.show" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="adsStart.show = false">close</v-icon><br />
                <h2 class="mt-2 font-weight-bold headline">
                  {{ adsStart.state === 'start' ? '광고 시작하기' : '광고 상품 변경하기' }}
                </h2>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="mb-6">
                  <div class="body-1">
                    <span class="rouge--text">'{{ adsData.name }}'</span>의
                    <span class="rouge--text">{{ adsStart.type === 'naver' ? '네이버 블로그' : '인스타그램' }}</span>
                    광고를
                    {{ adsStart.state === 'start' ? '시작합니다' : '변경합니다' }}.
                  </div>
                  <div class="body-1" v-if="adsStart.state === 'update'">
                    <span class="rouge--text">변경된 내용은 다음 결제일부터 적용됩니다.</span>
                  </div>
                </v-col>
                <v-col cols="12" class="mb-2">
                  <div class="body-1 font-weight-bold mb-2">광고 상품 선택</div>
                  <v-row v-if="adsStart.type === 'naver'">
                    <v-checkbox
                      v-model="adsStart.naver_type"
                      @change="changeType"
                      label="실속형(1~20명)"
                      color="rouge"
                      value="naver_n"
                      hide-details
                      class="checkbox pl-2 pt-2 mr-2 mb-2">
                    </v-checkbox>
                    <v-checkbox
                      v-model="adsStart.naver_type"
                      @change="changeType"
                      label="표준형(~100명)"
                      color="rouge"
                      value="naver_s"
                      hide-details
                      class="checkbox pl-2 pt-2">
                    </v-checkbox>
                  </v-row>
                  <v-row v-if="adsStart.type === 'insta'">
                    <v-checkbox
                      v-model="adsStart.insta_type"
                      @change="changeType"
                      label="실속형(1~20명)"
                      color="rouge"
                      value="insta_n"
                      hide-details
                      class="checkbox pl-2 pt-2 mr-2 mb-2">
                    </v-checkbox>
                    <v-checkbox
                      v-model="adsStart.insta_type"
                      @change="changeType"
                      label="표준형(~100명)"
                      color="rouge"
                      value="insta_s"
                      hide-details
                      class="checkbox pl-2 pt-2">
                    </v-checkbox>
                  </v-row>
                </v-col>
                <!--                <v-col cols="12" md="10" class="mb-6">-->
                <!--                  <v-row>-->
                <!--                    <v-col cols="12" md="12" class="estimate">-->
                <!--                      <bulbIcon class="light_bulb"/>-->
                <!--                      <div v-if="option.hasOwnProperty('price') && option.price">-->
                <!--                        추가되는 광고 비용은<br>-->
                <!--                        월 {{((naver_type === 'naver_n') ? option.price.naver.product.N/10000 : 0) + ((this.insta_type === 'insta_n') ? option.price.insta.product.N/10000 : 0) +-->
                <!--                        ((naver_type === 'naver_s') ? option.price.naver.product.S/10000 : 0) + ((this.insta_type === 'insta_s') ? option.price.insta.product.S/10000 : 0)}}만원 입니다.-->
                <!--                      </div>-->
                <!--                    </v-col>-->
                <!--                  </v-row>-->
                <!--                </v-col>-->
                <!--                <v-col cols="12" v-if="adsStart.state === 'start'">-->
                <!--                  <div class="body-1 font-weight-bold">광고 시작 예정일</div>-->
                <!--                  <v-menu ref="adsStartMenu" :close-on-content-click="false" v-model="adsStart.menu" :nudge-right="40"-->
                <!--                          lazy transition="scale-transition" offset-y min-width="290px">-->
                <!--                    <template v-slot:activator="{ on }">-->
                <!--                      <v-text-field v-on="on" v-model="adsStart.dateFormatted" prepend-icon="event"-->
                <!--                                    @blur="adsStart.startDate = parseDate(adsStart.dateFormatted)" style="max-width: 200px"-->
                <!--                      ></v-text-field>-->
                <!--                    </template>-->
                <!--                    <v-date-picker v-model="adsStart.startDate" locale="ko-kr" :min="$moment.tz('Asia/Seoul').add(1, 'day').format('YYYY-MM-DD')" no-title scrollable @input="adsStart.menu = false">-->
                <!--                    </v-date-picker>-->
                <!--                  </v-menu>-->
                <!--                </v-col>-->
                <v-col cols="12" v-if="adsStart.state === 'update'">
                  <div class="body-1 font-weight-bold">광고 변경 예정일</div>
                  <v-text-field
                    :value="$moment(adsStart.startDate).tz('Asia/Seoul').add(30, 'day').format('YYYY. MM. DD')"
                    prepend-icon="event"
                    readonly
                    style="width: 200px"></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    adsStart.type === 'naver' &&
                    (adsStart.naver_type === 'naver_n' || adsStart.naver_type === 'naver_s')
                  ">
                  <div class="body-1 font-weight-bold mb-2">모집 희망인원</div>
                  <!--                  <v-checkbox v-if="naver_type === 'naver_s'" v-model="adsStart.naver_limit" label="제한 없음" color="rouge" class="check ma-0" height="20px"></v-checkbox>-->
                  <v-row v-if="!adsStart.naver_limit">
                    <v-col class="pa-1" cols="8" md="8">
                      <v-slider
                        v-if="adsStart.naver_type === 'naver_n'"
                        v-model="adsStart.naver_number"
                        @change="checkDone"
                        always-dirty
                        :min="adsData.type === 'A' ? 5 : 10"
                        :max="adsData.type === 'A' ? 50 : 100"
                        :tick-labels="ticknLabels"
                        :tick-size="0"
                        :step="adsData.type === 'A' ? 5 : 10"
                        hide-details></v-slider>
                      <v-slider
                        v-else-if="adsStart.naver_type === 'naver_s'"
                        v-model="adsStart.naver_number"
                        @change="checkDone"
                        always-dirty
                        min="adsData.type === 'A' ? 5 : 10"
                        max="adsData.type === 'A' ? 50 : 100"
                        :tick-labels="ticksLabels"
                        :tick-size="0"
                        :step="adsData.type === 'A' ? 5 : 10"
                        hide-details></v-slider>
                    </v-col>
                    <v-col cols="4" class="pl-6">
                      <v-text-field
                        v-model.number="adsStart.naver_number"
                        class="mt-0"
                        type="number"
                        :suffix="adsData.type === 'A' ? '명' : '명/월'"
                        readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    adsStart.type === 'insta' &&
                    (adsStart.insta_type === 'insta_n' || adsStart.insta_type === 'insta_s')
                  "
                  class="my-4">
                  <div class="body-1 font-weight-bold mb-2">모집 희망인원</div>
                  <!--                  <v-checkbox v-if="insta_type === 'insta_s'" v-model="adsStart.insta_limit" label="제한 없음" color="rouge" class="check ma-0" height="20px"></v-checkbox>-->
                  <v-row v-if="!adsStart.insta_limit">
                    <v-col class="pa-1" cols="8" md="8">
                      <v-slider
                        v-if="adsStart.insta_type === 'insta_n'"
                        v-model="adsStart.insta_number"
                        @change="checkDone"
                        always-dirty
                        :min="adsData.type === 'A' ? 5 : 10"
                        :max="adsData.type === 'A' ? 50 : 100"
                        :tick-labels="ticknLabels"
                        :tick-size="0"
                        :step="adsData.type === 'A' ? 5 : 10"
                        hide-details></v-slider>
                      <v-slider
                        v-else-if="adsStart.insta_type === 'insta_s'"
                        v-model="adsStart.insta_number"
                        @change="checkDone"
                        always-dirty
                        :min="adsData.type === 'A' ? 5 : 10"
                        :max="adsData.type === 'A' ? 50 : 100"
                        :tick-labels="ticksLabels"
                        :tick-size="0"
                        :step="adsData.type === 'A' ? 5 : 10"
                        hide-details></v-slider>
                    </v-col>
                    <v-col cols="4" class="pl-6">
                      <v-text-field
                        v-model.number="adsStart.insta_number"
                        class="mt-0"
                        type="number"
                        :suffix="adsData.type === 'A' ? '명' : '명/월'"
                        readonly></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn class="rouge white-two--text" flat @click.native="addAds()">확인</v-btn>
              <v-btn color="primary" flat outlined @click.native="cancelAds">취소</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="adsEnd.show" content-class="white-two" persistent max-width="500px">
          <v-card class="pa-4">
            <v-card-title>
              <div>
                <v-icon color="default" @click="adsEnd.show = false">close</v-icon><br />
                <h2 class="mt-2 font-weight-bold headline">
                  {{ adsStart.state === 'start' ? '광고 시작하기' : '광고 상품 변경 예약완료' }}
                </h2>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <div class="body-1 mb-6">
                    <span class="rouge--text"
                      >'{{ adsStart.type === 'naver' ? '네이버 블로그' : '인스타그램' }}/{{ adsData.name }}'</span
                    >
                    광고가<br />
                    {{ adsStart.state === 'start' ? '등록되었습니다' : '다음 결제일부터 변경됩니다' }}.
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn class="rouge white-two--text" flat @click.native="adsEnd.show = false">완료</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: 'Page4',
  props: ['adsData', 'option'],
  components: {
    // bulbIcon: () => import('@/assets/img/regads/light-bulb.svg'),
    blackIcon: () => import('@/assets/img/regads/black-copy.svg'),
    redIcon: () => import('@/assets/img/regads/red-copy.svg'),
    yellowIcon: () => import('@/assets/img/regads/yellow-copy.svg'),
  },
  data: function () {
    return {
      MAX_REQUIRED_KEYWORD_COUNT: 3,
      form: {
        keywords: this.adsData.keywords || [],
        hashtags: this.adsData.hashtags || [],
        requiredKeywords: this.adsData.requiredKeywords || [],
        diamondPoint: this.adsData.diamondPoint || 0,
        blackPoint: this.adsData.blackPoint || 0,
        redPoint: this.adsData.redPoint || 0,
        yellowPoint: this.adsData.yellowPoint || 0,
      },
      channel: {
        limitation: (this.adsData.naver || {}).limitation || (this.adsData.insta || {}).limitation,
      },
      naverMenu: false,
      instaMenu: false,
      naverDateFormatted: null,
      instaDateFormatted: null,
      // isLimitation: false,
      naver_type: '',
      insta_type: '',
      insta_number: ((this.adsData.insta || {}).number === 0 ? 20 : (this.adsData.insta || {}).number) || 20,
      naver_number: ((this.adsData.naver || {}).number === 0 ? 20 : (this.adsData.naver || {}).number) || 20,
      insta_limit: (this.adsData.insta || {}).number === 0,
      naver_limit: (this.adsData.naver || {}).number === 0,
      adsStart: {
        show: false,
        type: 'naver',
        startDate: null,
        menu: false,
        dateFormatted: null,
        naver_type: '',
        insta_type: '',
        insta_number: ((this.adsData.instaNext || {}).number === 0 ? 20 : (this.adsData.instaNext || {}).number) || 20,
        naver_number: ((this.adsData.naverNext || {}).number === 0 ? 20 : (this.adsData.naverNext || {}).number) || 20,
        insta_limit: (this.adsData.instaNext || {}).number === 0,
        naver_limit: (this.adsData.naverNext || {}).number === 0,
      },
      adsEnd: {
        show: false,
      },
      naver_stopRequest: (this.adsData.naver || {}).stopRequest || false,
      insta_stopRequest: (this.adsData.insta || {}).stopRequest || false,
      keyword: '',
      requiredKeyword: '',
      hashtag: '',
      mission: '',
    };
  },
  computed: {
    ticknLabels() {
      return Array(10)
        .fill()
        .map((v, i) => {
          if ([10, 50, 100].indexOf((i + 1) * 10) >= 0) {
            return (i + 1) * 10;
          } else return '';
        });
    },
    ticksLabels() {
      return Array(10)
        .fill()
        .map((v, i) => {
          if ([10, 50, 100].indexOf((i + 1) * 10) >= 0) {
            return (i + 1) * 10;
          } else return '';
        });
    },
    // naverNumber: {
    //   get () {
    //   },
    //   set (value) {
    //   },
    // }
  },
  watch: {
    adsData: {
      handler: function (value) {
        this.form = {
          keywords: value.keywords || [],
          hashtags: value.hashtags || [],
          requiredKeywords: value.requiredKeywords || [],
          diamondPoint: value.diamondPoint || 0,
          blackPoint: value.blackPoint || 0,
          redPoint: value.redPoint || 0,
          yellowPoint: value.yellowPoint || 0,
        };
        this.channel = {
          limitation: (value.naver || {}).limitation || (value.insta || {}).limitation,
        };
        this.insta_number = ((value.insta || {}).number === 0 ? 20 : (value.insta || {}).number) || 20;
        this.naver_number = ((value.naver || {}).number === 0 ? 20 : (value.naver || {}).number) || 20;
        this.insta_limit = (value.insta || {}).number === 0;
        this.naver_limit = (value.naver || {}).number === 0;
        this.naver_stopRequest = (value.naver || {}).stopRequest || false;
        this.insta_stopRequest = (value.insta || {}).stopRequest || false;

        this.initData();
      },
      // immediate: true,
      deep: true,
    },
    'form.naver.startDate': function () {
      this.naverDateFormatted = this.formatDate(this.form.naver.startDate);
    },
    'form.insta.startDate': function () {
      this.instaDateFormatted = this.formatDate(this.form.insta.startDate);
    },
    'adsStart.startDate': function () {
      this.adsStart.dateFormatted = this.formatDate(this.adsStart.startDate);
    },
    naver_number: function (value) {
      if (this.$moment.tz('Asia/Seoul').startOf('d').isAfter(this.form.naver.startDate)) {
        if (this.adsData.naver && value < this.adsData.naver.number) {
          this.naver_number = this.adsData.naver.number;
        }
      }
    },
    insta_number: function (value) {
      if (this.$moment.tz('Asia/Seoul').startOf('d').isAfter(this.form.insta.startDate)) {
        if (this.adsData.insta && value < this.adsData.insta.number) {
          this.insta_number = this.adsData.insta.number;
        }
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${year}. ${month}. ${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('. ');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    changeType() {
      if (this.naver_type === 'naver_n') {
        this.naver_limit = false;
      }
      if (this.insta_type === 'insta_n') {
        this.insta_limit = false;
      }
    },
    addRequiredKeyword: async function () {
      if (
        this.requiredKeyword.length > 0 &&
        this.form.requiredKeywords.indexOf(this.requiredKeyword) === -1 &&
        this.MAX_REQUIRED_KEYWORD_COUNT > this.form.requiredKeywords.length
      ) {
        this.form.requiredKeywords.push(this.requiredKeyword);
        this.requiredKeyword = '';

        await this.checkDone();
        return null;
      }
    },
    removeRequiredKeyword: function (idx) {
      console.log('remove' + idx);
      this.form.requiredKeywords.splice(idx, 1);
      this.checkDone();
    },
    addKeyword: async function () {
      if (this.keyword.length > 0 && this.form.keywords.indexOf(this.keyword) === -1) {
        this.form.keywords.push(this.keyword);
        this.keyword = '';
        await this.checkDone();
      }
      return null;
    },
    removeKeyword: function (idx) {
      console.log('remove' + idx);
      this.form.keywords.splice(idx, 1);
      this.checkDone();
    },
    addHashtag: async function () {
      if (this.hashtag.length > 0 && this.form.hashtags.indexOf(this.hashtag) === -1) {
        this.form.hashtags.push(this.hashtag);
        this.hashtag = '';
        await this.checkDone();
      }
      return null;
    },
    removeHashtag: function (idx) {
      console.log('remove' + idx);
      this.form.hashtags.splice(idx, 1);
      this.checkDone();
    },
    addMission: function () {
      this.form.naver.mission = this.form.naver.mission || [];
      if (this.mission.length > 0 && this.form.naver.mission.indexOf(this.mission) === -1) {
        this.form.naver.mission.push(this.mission);
        this.mission = '';
        this.checkDone();
      }
    },
    removeMission: function (idx) {
      console.log('remove' + idx);
      this.form.naver.mission.splice(idx, 1);
      this.checkDone();
    },
    async checkDone() {
      let success = await this.$validator.validateAll();
      if (success) {
        let diamondPoint = this.form.diamondPoint;
        if (typeof diamondPoint === 'string') {
          diamondPoint = 0;
        }

        if (typeof diamondPoint === 'number') {
          diamondPoint = Math.min(Math.max(diamondPoint, 0), 500000);
        }

        this.form.diamondPoint = diamondPoint;

        // this.form.requiredKeywords =
        //   this.requiredKeyword.length > 0 ? [this.requiredKeyword] : null;
        ['naver', 'insta'].forEach(channel => {
          if (this[channel + '_type'].length > 0) {
            this._.merge(this.form[channel], JSON.parse(JSON.stringify(this.channel)));
            this.form[channel].type = this[channel + '_type'] === channel + '_n' ? 'N' : 'S';
            if (!this.isLimitation) {
              // delete this.form[channel].limitation;
              this.form[channel].limitation = null;
            }
            this.form[channel].number = this[channel + '_limit'] ? 0 : this[channel + '_number'];
            this.form[channel].stopRequest = this[channel + '_stopRequest'] || false;
            ['diamond', 'black', 'red', 'yellow'].forEach(level => {
              // NOTE : 블랙 등급의 소비자가, 환급금을 입력하면 다이아의 소비자가, 환급금도 이와 동일하게 설정
              if (level === 'diamond') {
                this.form[channel][level + 'FullPrice'] = this.form[channel]['blackFullPrice'] ?? 0;
                this.form[channel][level + 'Payment'] = this.form[channel]['blackPayment'] ?? 0;
                this.form[channel][level + 'Price'] = this.form[channel]['blackPrice'] ?? 0;
                this.form[channel][level] = this.form[channel]['black'] ?? '';
              }
              if (
                typeof this.form[channel][level + 'Price'] === 'string' &&
                this.form[channel][level + 'Price'].length === 0
              ) {
                this.form[channel][level + 'Price'] = 0;
              }
              if (
                typeof this.form[channel][level + 'FullPrice'] === 'string' &&
                this.form[channel][level + 'FullPrice'].length === 0
              ) {
                this.form[channel][level + 'FullPrice'] = 0;
              }
              if (
                typeof this.form[channel][level + 'Payment'] === 'string' &&
                this.form[channel][level + 'Payment'].length === 0
              ) {
                this.form[channel][level + 'Payment'] = 0;
              }
            });
          } else {
            delete this.form[channel];
          }

          if (this.form[channel]) {
            ['black', 'red', 'yellow'].forEach(level => {
              if (
                this.form[channel][level + 'FullPrice'] !== undefined &&
                this.form[channel][level + 'Payment'] !== undefined
              ) {
                this.form[channel][level + 'Price'] =
                  this.form[channel][level + 'FullPrice'] - this.form[channel][level + 'Payment'];
              }

              if ((this.form.category || {}).firstName === '매장') {
                this.form[channel][level] = Number(this.form[channel][level]);
              } else {
                this.form[channel][level] = (this.form[channel][level] || '') + '';
              }
            });
          }
        });

        // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
        this.$emit('updateEvent', this.form);
        this.$store.commit('setDirty', true);
      }
      return null;
    },
    showAdsStart(type, state) {
      if (state == 'update') {
        this.adsStart.show = true;
      }
      this.adsStart.type = type;
      this.adsStart.state = state;
      if (type === 'naver' && state === 'update') {
        this.adsStart.startDate = (this.adsData.naverNext || {}).startDate || this.adsData.naver.startDate;
      }
      if (type === 'insta' && state === 'update') {
        this.adsStart.startDate = (this.adsData.instaNext || {}).startDate || this.adsData.insta.startDate;
      }
      if (state == 'start') {
        this.addAds();
      }
    },
    addAds() {
      this.adsStart.show = false;
      this.adsEnd.show = true;

      let state = this.adsStart.state === 'start' ? this.adsStart.type : this.adsStart.type + 'Next';
      this.form[state] = {};
      this.form[state].startDate = this.adsStart.startDate;
      if (this.adsStart.state === 'start') {
        this[this.adsStart.type + 'DateFormatted'] = this.formatDate(this.form[state].startDate);
      }
      this[this.adsStart.type + '_type'] = this.adsStart[this.adsStart.type + '_type'];
      this.form[state].type = this[this.adsStart.type + '_type'] === this.adsStart.type + '_n' ? 'N' : 'S';
      if (!this.isLimitation) {
        delete this.form[state].limitation;
      }
      this[this.adsStart.type + '_number'] = this.adsStart[this.adsStart.type + '_number'];
      this.form[state].number = this[this.adsStart.type + '_limit'] ? 0 : this[this.adsStart.type + '_number'];

      // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
      this.$emit('updateEvent', this.form);
      this.$store.commit('setDirty', true);
      this.cancelAds();
    },
    cancelAds() {
      this.adsStart.show = false;
      ['naver', 'insta'].forEach(channel => {
        if (this.adsData[channel]) {
          this[channel + '_type'] = this.adsData[channel].type === 'N' ? channel + '_n' : channel + '_s';
          this[channel + 'DateFormatted'] = this.formatDate(this.form[channel].startDate);
        }
        this[channel + '_number'] =
          ((this.adsData[channel] || {}).number === 0 ? 20 : (this.adsData[channel] || {}).number) || 20;
        this[channel + '_limit'] = (this.adsData[channel] || {}).number === 0;
      });
    },
    initData() {
      ['naver', 'insta'].forEach(channel => {
        if (this.adsData && this.adsData[channel]) {
          this.form[channel] = this.adsData[channel];
          this.form[channel].black = this.form[channel].black || '';
          this.form[channel].red = this.form[channel].red || '';
          this.form[channel].yellow = this.form[channel].yellow || '';
          this[channel + 'DateFormatted'] = this.formatDate(this.adsData[channel].startDate);
          this.isLimitation = this.isLimitation || !!this.adsData[channel].limitation;
          this[channel + '_type'] = this.adsData[channel].type === 'N' ? channel + '_n' : channel + '_s';
        }
      });

      this.adsStart.startDate = this.$moment.tz('Asia/Seoul').add(1, 'day').format('YYYY-MM-DD');
      this.adsStart.dateFormatted = this.formatDate(this.adsStart.startDate);
      this.adsStart.insta_type =
        (this.adsData.instaNext || {}).type === undefined
          ? this.insta_type
          : (this.adsData.instaNext || {}).type === 'N'
          ? 'insta_n'
          : 'insta_s';
      this.adsStart.naver_type =
        (this.adsData.instaNext || {}).type === undefined
          ? this.naver_type
          : (this.adsData.instaNext || {}).type === 'N'
          ? 'naver_n'
          : 'naver_s';
      this.adsStart.insta_number =
        ((this.adsData.instaNext || {}).number === 0 ? 20 : (this.adsData.instaNext || {}).number) || this.insta_number;
      this.adsStart.naver_number =
        ((this.adsData.naverNext || {}).number === 0 ? 20 : (this.adsData.naverNext || {}).number) || this.naver_number;
      this.adsStart.insta_limit =
        (this.adsData.instaNext || {}).number === undefined
          ? this.insta_limit
          : (this.adsData.instaNext || {}).number === 0;
      this.adsStart.naver_limit =
        (this.adsData.naverNext || {}).number === undefined
          ? this.naver_limit
          : (this.adsData.naverNext || {}).number === 0;

      // if (this.adsData["requiredKeywords" || []].length > 0) {
      //   this.requiredKeyword = this.adsData.requiredKeywords[0];
      // }
    },
  },
  created() {
    this.initData();
  },
};
</script>
<style scoped lang="scss">
@import '../../../../assets/scss/custom';

.checkbox {
  border: 1px solid;
  border-radius: 8px;
  max-width: 170px;
  height: 40px;
  margin-top: 0px;
}

.line {
  border-bottom: 1px solid #c2c2c2;
  border-bottom: 1px solid var(--pinkish-grey-color);
  width: 100%;
}

.label {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
}

::v-deep .check {
  ::v-deep .v-label {
    font-size: 14px;
  }
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

.plus_btn {
  width: 40px;
  height: 40px !important;
  min-width: 40px !important;
  color: white;
  padding: 0 !important;
}
</style>
